import * as d3 from 'd3';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ProjectSummary, useEquitySummary, useRect } from '../hooks';
import { PieChart } from './PieChart';

const CHART_PADDING = 24;

const numberWithCommas = (x: number) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const EquityChart = () => {
  const [useDetailedData, setUseDetailedData] = React.useState(false);
  const data = useEquitySummary();
  const dataDeepCopy: ProjectSummary[] = JSON.parse(JSON.stringify(data));
  const summarizedData = React.useMemo(
    () =>
      dataDeepCopy.reduce((acc, cur) => {
        const primaryUserProject = dataDeepCopy
          .filter((p) => p.userName === cur.userName)
          .sort((a, b) => b.amount - a.amount)?.[0];

        if (JSON.stringify(primaryUserProject) !== JSON.stringify(cur)) {
          primaryUserProject.amount = primaryUserProject.amount + cur.amount;
          cur.amount = 0;
        }
        return [...acc, cur];
      }, [] as ProjectSummary[]),
    [dataDeepCopy]
  );
  const chartData = React.useMemo(
    () => ({
      detailedData: data,
      summarizedData: summarizedData,
      useDetailedData,
      setUseDetailedData,
    }),
    [data, summarizedData, useDetailedData]
  );
  const slices = React.useMemo(() => {
    const pieGenerator = d3
      .pie<ProjectSummary>()
      .value(({ amount }) => amount)
      .sort(null);
    return pieGenerator([...summarizedData].filter(({ amount }) => !!amount));
  }, [summarizedData]);
  const svgRef: any = React.useRef<SVGSVGElement>();
  const { width, height } = useRect(svgRef);
  const outerRadius = Math.min(width, height) / 2 - CHART_PADDING;
  const innerRadius = outerRadius / 2;
  const centerRadius = (outerRadius + innerRadius) / 2;
  const { breakpoints } = useTheme();
  const isMobile = React.useMemo(
    () => window.innerWidth < breakpoints.mobile,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [width]
  );
  const isActive = React.useMemo(
    () => useDetailedData && !isMobile,
    [useDetailedData, isMobile]
  );
  const sum = React.useMemo(
    () => slices.reduce((acc, cur) => acc + cur.value, 0),
    [slices]
  );
  const getEquity = React.useCallback(
    (value: number) => Math.round((value / sum) * 100),
    [sum]
  );

  return (
    <>
      <PieChartContainer
        ref={svgRef}
        isActive={isActive}
        height={window.innerWidth}
      >
        <PieChartLabels>
          {slices.map((slice) => {
            const label = slice.data.userName;
            const radians = (slice.endAngle + slice.startAngle - Math.PI) / 2;
            const x = centerRadius * Math.cos(radians) + width / 2;
            const y = centerRadius * Math.sin(radians) + height / 2;
            const equity = getEquity(slice.value);
            const alignedLeft = x - width / 2 <= 0;
            return (
              <PieChartLabelWrapper key={`label-${label}`} pos={{ x, y }}>
                <NameLabel>{label}</NameLabel>
                <ProjectDetails alignedLeft={alignedLeft}>
                  <ChartHorizontalRule />
                  {!isMobile &&
                    data
                      .filter(({ userName }) => userName === label)
                      .map((p, i) => (
                        <ProjectList
                          key={`${p.userName}-${p.projectName}-${i}`}
                        >
                          <ProjectName projectColor={p.projectColor}>
                            {p.projectName}
                          </ProjectName>
                          <FlexRow>
                            <ProjectDetail>
                              ${numberWithCommas(p.amount)}
                            </ProjectDetail>
                            <ProjectDetail>{p.duration}hrs</ProjectDetail>
                            <ProjectDetail>${p.rate}/hr</ProjectDetail>
                          </FlexRow>
                        </ProjectList>
                      ))}
                </ProjectDetails>
                <EquityLabel>{equity}%</EquityLabel>
              </PieChartLabelWrapper>
            );
          })}
        </PieChartLabels>
        <PieChartWrapper>
          <PieChart
            data={chartData}
            dimensions={{ width, height, innerRadius, outerRadius }}
          />
        </PieChartWrapper>
      </PieChartContainer>
      {isMobile &&
        slices.map((slice) => {
          const label = slice.data.userName;
          const equity = getEquity(slice.value);
          return (
            <MobileProjectDetails key={`label-${label}-mobile`}>
              <FlexRow>
                <NameLabel>{label}</NameLabel>
                <EquityLabel>{equity}%</EquityLabel>
              </FlexRow>
              <HorizontalRule />
              {data
                .filter(({ userName }) => userName === label)
                .map((p, i) => (
                  <MobileProjectList
                    key={`${p.userName}-${p.projectName}-${i}-mobile`}
                  >
                    <ProjectName projectColor={p.projectColor}>
                      {p.projectName}
                    </ProjectName>
                    <FlexRow>
                      <ProjectDetail>
                        ${numberWithCommas(p.amount)}
                      </ProjectDetail>
                      <ProjectDetail>{p.duration}hrs</ProjectDetail>
                      <ProjectDetail>${p.rate}/hr</ProjectDetail>
                    </FlexRow>
                  </MobileProjectList>
                ))}
            </MobileProjectDetails>
          );
        })}
    </>
  );
};

const FlexRow = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
`;

const PieChartLabels = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 2;
`;

const PieChartLabelWrapper = styled.div<{ pos: { x: number; y: number } }>`
  left: ${({ pos }) => pos.x}px;
  position: absolute;
  text-align: center;
  top: ${({ pos }) => pos.y}px;
  transform: translate3d(-50%, -50%, 0);
  width: 60px;
`;

const NameLabel = styled.p`
  font-size: 16px;
  margin-bottom: 6px;
  ${({ theme }) => theme.typography.heading}
`;

const EquityLabel = styled.p``;

const HorizontalRule = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.foreground.primary};
  margin: 4px 0px 6px 0px;
  width: 100%;
`;

const ChartHorizontalRule = styled(HorizontalRule)`
  margin: 0;
  position: absolute;
  top: -3px;
  transition: all 200ms ease-out;
  width: 60px;
`;

const ProjectDetails = styled.div<{
  alignedLeft: boolean;
}>`
  position: absolute;
  text-align: left;
  white-space: nowrap;

  ${({ alignedLeft }) =>
    alignedLeft
      ? `
    transform: translate3d(-100%, 0, 0);
    ${ChartHorizontalRule} { right: -60px; }
    `
      : `
    transform: translate3d(60px, 0, 0);
    ${ChartHorizontalRule} { left: -60px; }
    ${ProjectList} { padding-left: 12px; }
  `}

  ${FlexRow} {
    flex-wrap: nowrap;
  }
`;

const ProjectList = styled.div`
  transition: all 200ms ease-out;
  margin-bottom: 8px;
  opacity: 0;
  width: 100%;
`;

const ProjectName = styled.p<{ projectColor: string }>`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -12px;
    top: 4px;
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: ${({ projectColor }) => projectColor};
  }
`;

const ProjectDetail = styled.span`
  color: ${({ theme }) => theme.colors.foreground.primary};
  opacity: 0.5;

  &:after {
    content: '\\2022';
    margin: 0 4px;
  }

  &:last-child:after {
    display: none;
  }
`;

const PieChartWrapper = styled.div`
  transition: all 200ms ease-in;
`;

const MobileProjectList = styled.div`
  margin-bottom: 8px;
`;

const MobileProjectDetails = styled.div`
  margin: 0 auto;
  margin-bottom: ${CHART_PADDING}px;
  max-width: 240px;
  padding: 0px ${CHART_PADDING}px;

  ${NameLabel} {
    line-height: 1.4;
    margin-bottom: 0px;
  }

  ${EquityLabel} {
    margin-top: 6px;

    &:before {
      content: '\\2014';
      margin: 0px 6px;
    }
  }
`;

const PieChartContainer = styled.div<{ height: number; isActive: boolean }>`
  height: ${({ height }) => height}px;
  margin: 0 auto;
  max-height: 400px;
  position: relative;
  width: 100%;

  ${({ isActive }) =>
    isActive &&
    `
  {
    ${ProjectList} {
      opacity: 1;
    }

    ${PieChartWrapper} {
      opacity: 0.3;
    }

    ${ChartHorizontalRule} {
      width: calc(100% + 60px);
    }
  }
  `}
`;
