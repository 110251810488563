import axios from 'axios';
import React from 'react';
import dayjs from 'dayjs';

const STARTMOVEGO_START_DATE = dayjs(
  new Date('March 21, 2023 00:00:00')
).toISOString();

const clockify = axios.create({
  baseURL: `https://reports.api.clockify.me/v1/workspaces/${process.env.REACT_APP_CLOCKIFY_WORKSPACE_ID}`,
  timeout: 1000,
  headers: {
    'Content-Type': 'application/json',
    'x-api-key': process.env.REACT_APP_CLOCKIFY_API_KEY,
  },
});

export type TimeEntry = {
  amount: number;
  projectColor: string;
  projectName: string;
  rate: number;
  timeInterval: { duration: number };
  userName: string;
};

export const useClockifyDetailedReport = () => {
  const [timeEntries, setTimeEntries] = React.useState<TimeEntry[]>([]);

  React.useEffect(() => {
    const PAGE_SIZE = 1000;
    const getTimeEntries = async (
      page: number,
      entries: TimeEntry[]
    ): Promise<TimeEntry[]> => {
      const {
        data: { timeentries },
      }: { data: { timeentries: TimeEntry[] } } = await clockify.post(
        'reports/detailed',
        {
          dateRangeStart: STARTMOVEGO_START_DATE,
          dateRangeEnd: dayjs().toISOString(),
          detailedFilter: {
            page,
            pageSize: PAGE_SIZE,
          },
        }
      );
      entries = [...entries, ...timeentries];
      if (timeentries.length === PAGE_SIZE || timeentries.length === 0)
        return await getTimeEntries(page + 1, entries);
      else return entries;
    };
    const getAllTimeEntries = async () => {
      const entries = await getTimeEntries(1, []);
      setTimeEntries(entries);
    };
    getAllTimeEntries();
  }, []);

  return timeEntries;
};
