import React from 'react';
import styled from 'styled-components';
import { EquityChart } from '../components';

export const HomePage = () => (
  <Container>
    <Heading>
      StartMoveGo
      <br />
      Equity
    </Heading>
    <EquityChart />
  </Container>
);

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-top: 56px;

  @media only screen and (min-width: ${({ theme }) =>
      theme.breakpoints.mobile}px) {
    height: 100%;
    justify-content: center;
    min-height: 600px;
    max-height: 800px;
    padding-top: 0px;
  }
`;

const Heading = styled.h1`
  font-size: 24px;
  text-align: center;
  ${({ theme }) => theme.typography.heading}
`;
