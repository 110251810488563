import React from 'react';
import ReactSwitch from 'react-switch';
import styled, { useTheme } from 'styled-components';
import { useAppContext } from './AppContextProvider';
import { MoonIcon, SunIcon } from './icons';

export const Nav = () => {
  const { theme, setTheme } = useAppContext();
  const { colors } = useTheme();
  const checked = React.useMemo(() => theme === 'dark', [theme]);
  const onChange = () => setTheme(theme === 'dark' ? 'light' : 'dark');
  return (
    <Container>
      <Inner>
        <ReactSwitch
          onChange={onChange}
          checked={checked}
          onColor={colors.secondary}
          offColor={colors.secondary}
          onHandleColor={colors.foreground.primary}
          offHandleColor={colors.background.primary}
          uncheckedIcon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
          handleDiameter={18}
          height={24}
          width={48}
        />
      </Inner>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 9999;
`;

const Inner = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: 1440px;
  padding: 16px;
`;

const UncheckedIcon = styled(SunIcon).attrs(({ theme }) => ({
  fill: theme.colors.background.primary,
}))`
  position: absolute;
  top: 2px;
  right: 6px;
`;
const CheckedIcon = styled(MoonIcon).attrs(({ theme }) => ({
  fill: theme.colors.background.primary,
}))`
  position: absolute;
  top: 2px;
  left: 4px;
`;
