import React from 'react';
import {
  GoogleLogin,
  CredentialResponse,
  GoogleOAuthProvider,
} from '@react-oauth/google';
import jwtDecode from 'jwt-decode';
import styled, { useTheme } from 'styled-components';
import { useAppContext } from '../components';

const GOOGLE_OAUTH_CLIENT_ID =
  '304442283365-41lf6s0mekpsk3rh486efcgljv3lr6q0.apps.googleusercontent.com';

const VALID_USERS = ['shane@buchan.io', 'thenamesnickllc@gmail.com'];

export const LoginPage = () => {
  const [error, setError] = React.useState<string>();
  const theme = useTheme();
  const { setIsAuthenticated } = useAppContext();

  const onSuccess = (credentialResponse: CredentialResponse) => {
    if (!credentialResponse?.credential) return;
    const credential = jwtDecode<any>(credentialResponse.credential);
    if (VALID_USERS.includes(credential?.email)) setIsAuthenticated(true);
    else onError();
  };

  const onError = () => setError('Login Failed');

  return (
    <GoogleOAuthProvider clientId={GOOGLE_OAUTH_CLIENT_ID}>
      <Container>
        <Form>
          <Heading>Sign in</Heading>
          <GoogleLogin
            theme={theme.loginPage.googleTheme}
            shape="pill"
            onSuccess={onSuccess}
            onError={onError}
            width="200px"
          />
          {error && <Error>{error}</Error>}
        </Form>
      </Container>
    </GoogleOAuthProvider>
  );
};

const Container = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 300px;
  max-height: 800px;
`;

const Form = styled.div`
  background-color: ${({ theme }) => theme.loginPage.backgroundColor};
  border-radius: 8px;
  box-sizing: border-box;
  max-width: 100%;
  padding: 24px;
  text-align: center;
  width: 248px;
`;

const Heading = styled.h1`
  border-bottom: 1px solid ${({ theme }) => theme.loginPage.borderColor};
  font-size: 24px;
  line-height: 1;
  margin-bottom: 16px;
  padding-bottom: 8px;
  ${({ theme }) => theme.typography.heading}
`;

const Error = styled.p`
  color: ${({ theme }) => theme.colors.error};
  margin-top: 12px;
`;
