import React from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import 'normalize.css';
import { HomePage, LoginPage } from './pages';
import { Nav, useAppContext } from './components';

const App = () => {
  const { isAuthenticated } = useAppContext();
  const navigate = useNavigate();

  React.useEffect(() => {
    if (isAuthenticated) navigate('/');
    else navigate('/login');
  }, [isAuthenticated, navigate]);

  return (
    <>
      <GlobalStyle />
      <Nav />
      <Routes>
        {isAuthenticated && <Route path="/" element={<HomePage />} />}
        <Route path="/login" element={<LoginPage />} />
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </>
  );
};

export default App;

const GlobalStyle = createGlobalStyle`
  body {
    background-color: ${({ theme }) => theme.colors.background.primary};
    color: ${({ theme }) => theme.colors.foreground.primary};
    font-family: ${({ theme }) => theme.fontFamilies.body};
    font-size: 12px;
    line-height: 1.4;
  }

  html, body, #root {
    height: 100%;
  }

  p, h1 {
    margin: 0;
  }    
`;
