import * as d3 from 'd3';
import React from 'react';
import { useTheme } from 'styled-components';
import { ProjectSummary } from '../hooks';

export const PieChart: React.FC<IChart> = ({ data, dimensions }) => {
  const svgRef = React.useRef<SVGSVGElement>(null);
  const isInit = React.useRef(true);
  const theme = useTheme();

  React.useEffect(() => {
    if (!svgRef?.current) return;
    const { width, height, innerRadius, outerRadius } = dimensions;

    const svg = d3.select(svgRef?.current);
    svg.selectAll('*').remove();

    const pieGenerator = d3
      .pie<ProjectSummary>()
      .value(({ amount }) => amount)
      .sort(null);

    const arcGenerator = d3
      .arc<ISlice>()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius);

    const slices = pieGenerator([
      ...(!data.useDetailedData ? data.detailedData : data.summarizedData),
    ]);
    const detailedSlices = pieGenerator([...data.detailedData]);
    const summarizedSlices = pieGenerator([...data.summarizedData]);

    // Draw the pie
    const piePosition = `translate(${width / 2}, ${height / 2})`;
    svg
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', piePosition)
      .selectAll('path')
      .data(slices)
      .join('path')
      .attr('fill', (d) => d?.data?.projectColor)
      .attr('d', arcGenerator);

    // Draw slice outlines
    svg
      .append('g')
      .attr('transform', piePosition)
      .selectAll('path')
      .data(summarizedSlices)
      .join('path')
      .attr('fill-opacity', 0)
      .attr('d', arcGenerator)
      .style('stroke', theme.colors.background.primary)
      .style('stroke-width', 3)
      .on('mouseenter', () => data.setUseDetailedData(true))
      .on('mouseleave', () => data.setUseDetailedData(false));

    // Animate arcs and data transforms
    const oldSlices = !data.useDetailedData ? summarizedSlices : detailedSlices;
    const arcTween = (d: ISlice, i: number) => {
      const oldSlice = { ...d };
      d.startAngle = oldSlices[i].startAngle;
      d.endAngle = oldSlices[i].endAngle;
      if (isInit.current) {
        oldSlice.startAngle = oldSlice.endAngle;
      }
      const interpolate = d3.interpolate(oldSlice, d);
      return (t: number) => arcGenerator(interpolate(t)) || '';
    };

    svg
      .selectAll('path')
      .data(slices)
      .transition()
      .duration(isInit.current ? 600 : 200)
      .delay(0)
      .attrTween('d', arcTween)
      .on('start', () => {
        if (data.useDetailedData && isInit.current) isInit.current = false;
      });
  }, [data, dimensions, theme]);

  return <svg ref={svgRef} />;
};

export type ISlice = d3.PieArcDatum<ProjectSummary>;

interface IDimensions {
  width: number;
  height: number;
  innerRadius: number;
  outerRadius: number;
}

interface IChart {
  data: {
    detailedData: ProjectSummary[];
    summarizedData: ProjectSummary[];
    useDetailedData: boolean;
    setUseDetailedData: (use: boolean) => void;
  };
  dimensions: IDimensions;
}
