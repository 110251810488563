import React from 'react';
import {
  TimeEntry,
  useClockifyDetailedReport,
} from './useClockifyDetailedReport';

export type ProjectSummary = {
  amount: number;
  duration: number;
  projectColor: string;
  projectName: string;
  rate: number;
  userName: string;
};

const roundToHundredth = (n: number) => {
  return Math.round((n + Number.EPSILON) * 100) / 100;
};

const mungeClockifyTimeEntries = (timeEntries: TimeEntry[]) =>
  timeEntries
    .reduce(
      (
        acc,
        {
          amount,
          projectColor,
          projectName,
          rate,
          timeInterval: { duration },
          userName,
        }
      ) => {
        const createNewProject = (): ProjectSummary => ({
          amount,
          duration,
          projectColor,
          projectName,
          rate,
          userName,
        });
        const project = acc.find(
          (project) =>
            project.projectName === projectName &&
            project.userName === userName &&
            project.rate === rate
        );
        if (project) {
          project.duration = project.duration + duration;
          project.amount = project.amount + amount;
        } else {
          acc.push(createNewProject());
        }
        return acc;
      },
      [] as ProjectSummary[]
    )
    .sort((a, b) => a.amount - b.amount)
    .sort((a, b) => a.userName.localeCompare(b.userName))
    .map(({ rate, amount, duration, ...rest }) => ({
      amount: roundToHundredth(amount / 100), // convert to dollars
      duration: roundToHundredth(duration / 60 / 60), // convert to hours
      rate: roundToHundredth(rate / 100), // convert to dollars
      ...rest,
    }));

export const useEquitySummary = () => {
  const timeEntries = useClockifyDetailedReport();
  const equitySummary: ProjectSummary[] = React.useMemo(
    () => mungeClockifyTimeEntries(timeEntries),
    [timeEntries]
  );
  return equitySummary;
};
