import { MutableRefObject, RefObject, useCallback, useState } from 'react';
import { useResizeObserver } from './useResizeObserver';

const emptyRect: DOMRect = {
  width: 0,
  height: 0,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  x: 0,
  y: 0,
  toJSON: () => undefined,
};

export const useRect = (
  ref: RefObject<any> | MutableRefObject<any>
): DOMRect => {
  const [rect, setRect] = useState<DOMRect>(emptyRect);

  const handleResize = useCallback(() => {
    if (ref.current) setRect(ref.current.getBoundingClientRect());
  }, [ref]);

  useResizeObserver(ref, handleResize);

  return rect;
};
