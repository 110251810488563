import React from 'react';
import { DefaultTheme } from 'styled-components';

export const useGoogleFonts = ({ fontSource }: DefaultTheme) => {
  React.useEffect(() => {
    if (!fontSource) return;

    let googleFontRef = document.getElementById(
      'google-font'
    ) as HTMLLinkElement;
    if (!googleFontRef) {
      googleFontRef = document.createElement('link');
      googleFontRef.rel = 'stylesheet';
      googleFontRef.id = 'google-font';
      document.head.appendChild(googleFontRef);
    }
    googleFontRef.href = fontSource;
  }, [fontSource]);
};
