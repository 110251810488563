import React from 'react';

export const MoonIcon: React.FC<React.SVGProps<SVGSVGElement>> = ({
  fill,
  ...rest
}) => (
  <svg width="20" height="20" viewBox="0 0 845 845" {...rest}>
    <path
      d="M609.699 531.421C446.139 531.421 313.525 398.823 313.525 235.235C313.525 200.052 319.692 166.308 330.947 135C216.701 176.093 135 285.36 135 413.76C135 577.333 267.604 709.947 431.187 709.947C559.577 709.947 668.853 628.249 709.947 514C678.64 525.255 644.905 531.421 609.707 531.421H609.699Z"
      fill={fill}
    />
  </svg>
);
