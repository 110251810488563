import { GsiButtonConfiguration } from '@react-oauth/google';
import {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from 'styled-components';

export type ThemeType = {
  colors: {
    primary: string;
    secondary: string;
    error: string;
    warning: string;
    info: string;
    success: string;
    background: {
      primary: string;
      secondary: string;
    };
    foreground: {
      primary: string;
      secondary: string;
    };
  };
  fontSource: string;
  fontFamilies: {
    body: string;
    heading: string;
  };
  loginPage: {
    backgroundColor: string;
    borderColor: string;
    googleTheme: GsiButtonConfiguration['theme'];
  };
  typography: {
    heading: FlattenInterpolation<ThemeProps<DefaultTheme>>;
  };
  breakpoints: {
    mobile: number;
  };
};

const defaultTheme: DefaultTheme = {
  colors: {
    primary: '#90caf9',
    secondary: '#ce93d8',
    error: '#f44336',
    warning: '#ffa726',
    info: '#29b6f6',
    success: '#66bb6a',
    background: {
      primary: '#212121',
      secondary: '#424242',
    },
    foreground: {
      primary: '#FAFAFA',
      secondary: '#9E9E9E',
    },
  },
  fontSource:
    'https://fonts.googleapis.com/css2?family=Courier+Prime&family=Mulish:wght@900&display=swap',
  fontFamilies: {
    body: "'Courier Prime', monospace",
    heading: "'Mulish', sans-serif",
  },
  loginPage: {
    backgroundColor: 'rgba(0, 0, 0, 0.2)',
    borderColor: 'rgba(255, 255, 255, 0.2)',
    googleTheme: 'filled_black',
  },
  typography: {
    heading: css`
      font-family: ${({ theme }) => theme.fontFamilies.heading};
      font-variant: small-caps;
      font-weight: 900;
      letter-spacing: 0.06em;
      line-height: 1;
      text-transform: lowercase;
    `,
  },
  breakpoints: {
    mobile: 800,
  },
};

const lightTheme: DefaultTheme = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    background: {
      primary: '#FAFAFA',
      secondary: '#E0E0E0',
    },
    foreground: {
      primary: '#212121',
      secondary: '#424242',
    },
  },
  loginPage: {
    backgroundColor: '#E0E0E0',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    googleTheme: 'outline',
  },
};

export const themes = {
  dark: defaultTheme,
  light: lightTheme,
};
