import React from 'react';
import { useCookies } from 'react-cookie';
import { ThemeProvider } from 'styled-components';
import { useGoogleFonts } from '../hooks';
import { themes } from '../styles/theme';

type Theme = keyof typeof themes;
enum Cookies {
  'theme',
}

type AppContextType = {
  theme: Theme;
  setTheme: (theme: Theme) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  setCookie: (name: keyof typeof Cookies, value: any) => void;
};

const AppContext = React.createContext<AppContextType>({
  theme: 'dark',
  setTheme: () => {},
  isAuthenticated: false,
  setIsAuthenticated: () => {},
  setCookie: () => {},
});

const ONE_MONTH = 2629746; // in seconds

export const AppContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [cookies, _setCookie] = useCookies(Object.keys(Cookies));
  const theme: Theme = React.useMemo(
    () => cookies?.theme || 'dark',
    [cookies?.theme]
  );
  useGoogleFonts(themes[theme]);

  const setCookie = (name: keyof typeof Cookies, value: any) => {
    _setCookie(name, value, { maxAge: ONE_MONTH });
  };

  const setTheme = (theme: Theme) => setCookie('theme', theme);

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        isAuthenticated,
        setIsAuthenticated,
        setCookie,
      }}
    >
      <ThemeProvider theme={themes[theme]}>{children}</ThemeProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => React.useContext(AppContext);
